export const Routes = {
  Home: {
    path: "/",
    name: "DANIEL GIL"
  },
  ArtPage: {
    path: "/art",
    name: "art"
  },
  BlogPage: {
    path: "/blog",
    name: "blog"
  },
  CodePage: {
    path: "/code",
    name: "code"
  },
  ContactPage: {
    path: "/contact",
    name: "contact"
  },
  MusicPage: {
    path: "/music",
    name: "music"
  }
};
