import React from "react";

export const wrapperStyle: React.CSSProperties = {
  paddingBottom: 10
};

export const separatorStyle: React.CSSProperties = {
  borderBottom: "solid black 2px",
  width: "70%",
  maxWidth: 500,
  margin: "auto",
  marginTop: 25,
  marginBottom: 25
};

export const blockColumnStyle: React.CSSProperties = {
  display: "inline-block",
  verticalAlign: "middle"
};

export const bodyStyle: React.CSSProperties = {
  width: "100%"
};
